/* Import these styles in your main CSS or directly in the component */
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@keyframes move {

  0%,
  100% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(24px);
  }
}

@keyframes grow {

  0%,
  100% {
    width: 8px;
    height: 8px;
    opacity: 1;
  }

  50% {
    width: 24px;
    height: 24px;
    opacity: 0.7;
  }
}

.dot {
  animation: move 1.5s infinite, grow 1.5s infinite;
}

.dot:nth-child(1) {
  animation-delay: 0s;
}

.dot:nth-child(2) {
  animation-delay: 0.3s;
}

.dot:nth-child(3) {
  animation-delay: 0.6s;
}

.dot:nth-child(4) {
  animation-delay: 0.9s;
}

.dot-1 {
  background-color: #FF2EAB;
}

.dot-2 {
  background-color: #FF2EAB;
}

.dot-3 {
  background: linear-gradient(to right, #FF2EAB, #FFA030);
}

.dot-4 {
  background-color: #FFA030;
}

.loader-wrapper {
  position: relative;
  height: 50px;
}

.background-pattern {
  background-size: cover;
  background-position: center;
}


.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.react-tel-input {
  width: 100%;
}

.react-tel-input .form-control {
  width: 100% !important;
  border: 2px solid transparent !important;
  border-radius: 8px !important;
  padding: 12px 42px !important;
  height: 47.2px !important;
  background-color: rgba(33, 45, 54, 0.06) !important;
  color: #3d3d3d !important;
  outline: none !important;
}

.react-tel-input .flag-dropdown {
  background: none !important;
  border: none !important;
  padding-right: 10px !important;
}

.react-tel-input .form-control:focus {
  border-color: #3D86FF !important;
  box-shadow: none !important;
}

.reward-card-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 0;
}

.gradient-star {
  background: linear-gradient(to right, #EC4899, #FB923C);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.custom-dash-line {
  border: none;
  border-top: 2px dashed #ccc;
  margin: 20px 0;
  /* Adjust as needed */
  height: 0;
  border-image: repeating-linear-gradient(to right, #D9D9D9 0, #D9D9D9 5px, transparent 5px, transparent 10px) 100;
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.circle-animation {
  position: absolute;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 2px solid rgba(255, 107, 107, 0.5);
  animation: pulse 2s infinite;
}

.circle-animation.delay-1 {
  animation-delay: 0.5s;
}

.circle-animation.delay-2 {
  animation-delay: 1s;
}

@keyframes pulse {
  0% {
    transform: scale(0.5);
    opacity: 1;
  }

  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.ticket-card {
  position: relative;
}

.ticket-card::before,
.ticket-card::after {
  content: '';
  position: absolute;
  top: 49%;
  width: 40px; /* Adjust the width for the size of the cutout */
  height: 40px; /* Adjust the height for the size of the cutout */
  background-color: #ffffff; /* Match card background color */
  border-radius: 50%;
  transform: translateY(-50%);
  z-index: 1;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);  
}

.ticket-card::before {
  left: -25px; /* Move it slightly off the left edge */
}

.ticket-card::after {
  right: -25px; /* Move it slightly off the right edge */
}


@media (max-width: 375px) and (max-height: 667px) {
  .choose-donation-heading {
    font-size: 1.5rem; /* Adjust the heading size */
    margin-top: 2rem;  /* Adjust margin */
  }

  .choose-donation-paragraph {
    font-size: 0.875rem; /* Smaller text size for iPhone SE */
  }

  .choose-donation-button {
    margin-top: 1rem; /* Adjust button spacing */
  }

  .choose-donation-container {
    padding: 1rem; /* Reduce padding for smaller devices */
  }
}

.gradient-custom {
  background: linear-gradient(67.88deg, #FF2EAB 12.85%, #FFA030 84.04%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
